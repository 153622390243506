/**
 * Do not edit directly
 * Generated on Fri, 20 Oct 2023 14:33:20 GMT
 */

:root {
    --button-primary-enabled-background: #c8faa0;
    --button-primary-enabled-foreground: #000000;
    --button-primary-hover-background: #d8fabd;
    --button-primary-hover-foreground: #000000;
    --button-primary-focus-background: #d8fabd;
    --button-primary-focus-foreground: #000000;
    --button-primary-active-background: #95bc76;
    --button-primary-active-foreground: #000000;
    --button-primary-disabled-background: #c6c6c6;
    --button-primary-disabled-foreground: #6f6f6f;
    --card-primary-background: #d8fabd;
    --card-primary-foreground: #000000;
    --card-neutral-background: #f4f4f4;
    --card-neutral-foreground: #000000;
    --card-white-background: #ffffff;
    --card-white-foreground: #000000;
    --link-enabled-foreground: #95bc76;
    --link-hover-foreground: #627e4b;
    --partners-background: #c8faa0;
    --partners-foreground: #e1e1f0;
    --text-button-foreground: #627e4b;
    --text-button-l-font-size: 30px;
    --text-button-m-font-size: 20px;
    --text-button-s-font-size: 16px;
    --tile-primary-background: #c8faa0;
    --tile-primary-foreground: #39499c;
    --tile-neutral-background: #f4f4f4;
    --tile-neutral-foreground: #000000;
    --border-color-primary-default: #c8faa0;
    --border-color-primary-lighter: #d8fabd;
    --border-color-primary-light: #e2fbce;
    --border-color-neutral-default: #c6c6c6;
    --border-color-neutral-darkest: #000000;
    --border-radius-l: 9999px;
    --border-width-s: 0px;
    --border-width-m: 1px;
    --border-width-l: 1.5px;
    --border-width-xl: 2px;
    --border-width-xxl: 4px;
    --color-yellow-40: #f0ebb5;
    --color-yellow-50: #e6de82;
    --color-yellow-60: #e6dc6e;
    --color-yellow-70: #b09646;
    --color-yellow-80: #7b5827;
    --color-yellow-90: #3b2a13;
    --color-light-purple-40: #e1e1f0;
    --color-light-purple-50: #d6d6ea;
    --color-light-purple-60: #c8c8e6;
    --color-light-purple-70: #9595b0;
    --color-light-purple-80: #63637b;
    --color-light-purple-90: #2f2f3b;
    --color-lime-green-40: #e2fbce;
    --color-lime-green-50: #d8fabd;
    --color-lime-green-60: #c8faa0;
    --color-lime-green-70: #95bc76;
    --color-lime-green-80: #627e4b;
    --color-lime-green-90: #303d24;
    --color-red-40: #f9ada9;
    --color-red-50: #f7918c;
    --color-red-60: #ff665e;
    --color-red-70: #bf4c46;
    --color-red-80: #7f332f;
    --color-red-90: #401a18;
    --color-grey-40: #afaeb9;
    --color-grey-50: #9391a1;
    --color-grey-60: #6d6b82;
    --color-grey-70: #504d76;
    --color-grey-80: #342e6c;
    --color-grey-90: #15122b;
    --color-dark-blue-40: #939bc4;
    --color-dark-blue-50: #6e78b0;
    --color-dark-blue-60: #39499c;
    --color-dark-blue-70: #263685;
    --color-dark-blue-80: #132270;
    --color-dark-blue-90: #080f30;
    --color-neutral-10: #f4f4f4;
    --color-neutral-20: #e0e0e0;
    --color-neutral-30: #c6c6c6;
    --color-neutral-40: #a8a8a8;
    --color-neutral-50: #8d8d8d;
    --color-neutral-60: #6f6f6f;
    --color-neutral-70: #525252;
    --color-neutral-80: #393939;
    --color-neutral-90: #262626;
    --color-neutral-100: #161616;
    --color-neutral-white: #ffffff;
    --color-neutral-black: #000000;
    --color-positive-40: #34c759;
    --color-positive-50: #2fb350;
    --color-positive-60: #2aa148;
    --color-positive-70: #269141;
    --color-positive-80: #22823a;
    --color-informative-40: #2680eb;
    --color-informative-50: #1473e6;
    --color-informative-60: #0d66d0;
    --color-informative-70: #095aba;
    --color-informative-80: #084ea1;
    --color-notice-40: #e68619;
    --color-notice-50: #da7b11;
    --color-notice-60: #cb6f10;
    --color-notice-70: #bd640d;
    --color-notice-80: #864906;
    --color-negative-40: #ff3a46;
    --color-negative-50: #e5343f;
    --color-negative-60: #ce2f39;
    --color-negative-70: #ba2a33;
    --color-negative-80: #a7262e;
    --font-family-sans-serif: Graphik, arial, sans-serif;
    --font-size-1: 12px;
    --font-size-2: 14px;
    --font-size-3: 16px;
    --font-size-4: 18px;
    --font-size-5: 20px;
    --font-size-6: 24px;
    --font-size-7: 30px;
    --font-size-8: 34px;
    --font-size-9: 40px;
    --font-size-10: 60px;
    --font-weight-xxl: 700;
    --font-weight-xl: 600;
    --font-weight-l: 500;
    --font-weight-m: 400;
    --font-weight-s: 300;
    --line-height-m: 1.75;
    --line-height-s: 1.5;
    --line-height-xs: 1.25;
    --line-height-xxs: 1;
    --media-query-xl: 1440px;
    --media-query-l: 1280px;
    --media-query-m: 1024px;
    --media-query-s: 768px;
    --media-query-xs: 480px;
    --sizing-1: 4px;
    --sizing-2: 8px;
    --sizing-3: 12px;
    --sizing-4: 16px;
    --sizing-5: 20px;
    --sizing-6: 24px;
    --sizing-7: 32px;
    --sizing-8: 40px;
    --sizing-9: 48px;
    --sizing-10: 56px;
    --sizing-11: 64px;
    --sizing-12: 72px;
    --sizing-13: 80px;
    --sizing-14: 96px;
    --sizing-15: 104px;
    --sizing-16: 128px;
    --sizing-17: 160px;
    --sizing-18: 192px;
    --sizing-19: 224px;
    --sizing-20: 256px;
    --syntax-background-neutral-default: #ffffff;
    --syntax-background-neutral-dark: #f4f4f4;
    --syntax-background-neutral-darkest: #000000;
    --syntax-background-primary-lightest: #e2fbce;
    --syntax-background-primary-light: #d8fabd;
    --syntax-background-primary-default: #c8faa0;
    --syntax-background-primary-dark: #95bc76;
    --syntax-background-primary-darker: #627e4b;
    --syntax-background-primary-darkest: #303d24;
    --syntax-body-text-l-regular-font-size: 16px;
    --syntax-body-text-l-regular-line-height: 1.75;
    --syntax-body-text-l-regular-font-family: Graphik, arial, sans-serif;
    --syntax-body-text-l-regular-font-weight: 400;
    --syntax-body-text-s-regular-font-size: 16px;
    --syntax-body-text-s-regular-line-height: 1.5;
    --syntax-body-text-s-regular-font-family: Graphik, arial, sans-serif;
    --syntax-body-text-s-regular-font-weight: 400;
    --syntax-color-action-enabled-background: #c8faa0;
    --syntax-color-action-enabled-foreground: #000000;
    --syntax-color-action-focus-background: #d8fabd;
    --syntax-color-action-focus-foreground: #000000;
    --syntax-color-action-hover-background: #d8fabd;
    --syntax-color-action-hover-foreground: #000000;
    --syntax-color-action-active-background: #95bc76;
    --syntax-color-action-active-foreground: #000000;
    --syntax-color-input-enabled-background: #ffffff;
    --syntax-color-input-enabled-border: #c6c6c6;
    --syntax-color-input-focus-background: #ffffff;
    --syntax-color-input-focus-border: #c8faa0;
    --syntax-color-input-hover-background: #ffffff;
    --syntax-color-input-hover-border: #c8faa0;
    --syntax-color-input-active-background: #ffffff;
    --syntax-color-input-active-border: #c8faa0;
    --syntax-color-input-invalid-background: #ffffff;
    --syntax-color-input-invalid-border: #ff3a46;
    --syntax-color-input-disabled-background: #f4f4f4;
    --syntax-color-input-disabled-border: #c6c6c6;
    --syntax-color-disabled-background: #c6c6c6;
    --syntax-color-disabled-foreground: #6f6f6f;
    --syntax-color-error-foreground: #ff3a46;
    --syntax-disabled-color-default: #c6c6c6;
    --syntax-disabled-color-dark: #6f6f6f;
    --syntax-heading-text-l-1-font-size: 60px;
    --syntax-heading-text-l-1-line-height: 1;
    --syntax-heading-text-l-1-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-l-1-font-weight: 700;
    --syntax-heading-text-l-2-font-size: 40px;
    --syntax-heading-text-l-2-line-height: 1;
    --syntax-heading-text-l-2-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-l-2-font-weight: 700;
    --syntax-heading-text-l-3-font-size: 30px;
    --syntax-heading-text-l-3-line-height: 1;
    --syntax-heading-text-l-3-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-l-3-font-weight: 700;
    --syntax-heading-text-l-4-font-size: 24px;
    --syntax-heading-text-l-4-line-height: 1.25;
    --syntax-heading-text-l-4-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-l-4-font-weight: 700;
    --syntax-heading-text-l-5-font-size: 18px;
    --syntax-heading-text-l-5-line-height: 1.25;
    --syntax-heading-text-l-5-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-l-5-font-weight: 700;
    --syntax-heading-text-s-1-font-size: 34px;
    --syntax-heading-text-s-1-line-height: 1.25;
    --syntax-heading-text-s-1-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-s-1-font-weight: 700;
    --syntax-heading-text-s-2-font-size: 24px;
    --syntax-heading-text-s-2-line-height: 1.25;
    --syntax-heading-text-s-2-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-s-2-font-weight: 700;
    --syntax-heading-text-s-3-font-size: 20px;
    --syntax-heading-text-s-3-line-height: 1.25;
    --syntax-heading-text-s-3-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-s-3-font-weight: 700;
    --syntax-heading-text-s-4-font-size: 18px;
    --syntax-heading-text-s-4-line-height: 1.25;
    --syntax-heading-text-s-4-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-s-4-font-weight: 700;
    --syntax-heading-text-s-5-font-size: 16px;
    --syntax-heading-text-s-5-line-height: 1.25;
    --syntax-heading-text-s-5-font-family: Graphik, arial, sans-serif;
    --syntax-heading-text-s-5-font-weight: 700;
    --syntax-overlay-color-default: rgba(0, 0, 0, 0.75);
    --syntax-overlay-color-darkest: rgba(0, 0, 0, 1);
    --syntax-pillar-color-collection-default: #c8c8e6;
    --syntax-pillar-color-collection-lighter: #e1e1f0;
    --syntax-pillar-color-festival-default: #e6dc6e;
    --syntax-pillar-color-festival-lighter: #f0ebb5;
    --syntax-pillar-color-institute-default: #ff665e;
    --syntax-pillar-color-institute-lighter: #f9ada9;
    --syntax-pillar-color-professionals-default: #c8faa0;
    --syntax-pillar-color-professionals-lighter: #e2fbce;
    --syntax-primary-color-light: #e2fbce;
    --syntax-primary-color-lighter: #d8fabd;
    --syntax-primary-color-default: #c8faa0;
    --syntax-primary-color-darker: #95bc76;
    --syntax-primary-color-dark: #627e4b;
    --syntax-primary-color-darkest: #303d24;
    --syntax-text-color-neutral-muted: #504d76;
    --syntax-text-color-neutral: #39499c;
    --syntax-text-color-default: #000000;
    --syntax-text-color-muted: #8d8d8d;
    --syntax-text-color-on-dark: #ffffff;
    --syntax-text-color-on-primary: #000000;
}
